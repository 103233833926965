import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Theme, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import {roomStore} from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore } from '../stores/global';
import { useTranslation } from 'react-i18next';
import {useRoomState, useStore} from "../containers/root-container";
import GlobalStorage from "../utils/custom-storage";

export const roomTypes = [
  {value: 0, text: '1v1授课', path: 'one-to-one'},
  {value: 1, text: '小班课', path: 'small-class'},
  {value: 2, text: '大班课', path: 'big-class'},
];

export const lanTypes = [
  {value: 0, text: '简体中文',path:"ch"},
  {value: 1, text: 'English',path:"en"},
];


const useStyles = makeStyles ((theme: Theme) => ({
  formControl: {
    minWidth: '240px',
    maxWidth: '240px',
  }
}));


type SessionInfo = {
  roomName: string
  roomType: number
  yourName: string
  role: string
  lan: number
}

const defaultState: SessionInfo = {
  roomName: "",
  roomType:  0,
  role:  '',
  yourName:  '',
  lan:0,
  ...GlobalStorage.read('room_session')
}


function HomePage() {

  const { t, i18n } = useTranslation();

  const changeLanguage = (language : string) => i18n.changeLanguage(language);

  const classes = useStyles();

  const history = useHistory();

  const handleSetting = (evt: any) => {
    history.push({pathname: `/device_test`});
  }

  const {
    HomeBtn
  } = usePlatform();

  const ref = useRef<boolean>(false);

  const roomState = useRoomState();

  const [session, setSessionInfo] = useState<SessionInfo>(defaultState);

  useEffect(() => {
    if(!roomStore._state) return;
    setSessionInfo({
      ...session,
      // role :  roomStore._state.me.role ? roomStore._state.me.role : '',
      roomName : roomStore._state.course.roomName ?roomStore._state.course.roomName : "",
      roomType :   (roomStore._state.course.roomType !== 0)? roomStore._state.course.roomType : 0,
      yourName : roomStore._state.me.account ? roomStore._state.me.account : '',
    });

  }, [roomStore]);

  const [required, setRequired] = useState<any>({} as any);

  const handleSubmit = () => {
    if (!session.roomName) {
      setRequired({...required, roomName: '请填写房间名称!'});
      return;
    }

    if (!session.yourName) {
      setRequired({...required, yourName: '请填写用户名!'});
      return;
    }

    if (!session.role) {
      setRequired({...required, role: '请选择角色!'});
      return;
    }

    if (!roomTypes[session.roomType]) return;
    const path = roomTypes[session.roomType].path
    const payload = {
      uid: genUid(),
      rid: `${session.roomType}${MD5(session.roomName)}`,
      role: session.role,
      roomName: session.roomName,
      roomType: session.roomType,
      video: 1,
      audio: 1,
      chat: 1,
      account: session.yourName,
      token: '',
      boardId: '',
      linkId: 0,
      sharedId: 0,
      lockBoard: 0,
    }
    ref.current = true;
    globalStore.showLoading();
    roomStore.loginAndJoin(payload).then(() => {
      roomStore.updateSessionInfo(payload);
      history.push(`/classroom/${path}`);
    }).catch((err: any) => {
      if (err.reason) {
        globalStore.showToast({
          type: 'rtmClient',
          message: err.reason
        })
      } else {
        globalStore.showToast({
          type: 'rtmClient',
          message: t('loginfailednettips')
        })
      }
      console.warn(err);
    })
    .finally(() => {
        ref.current = false;
        globalStore.stopLoading();
    })
  }

  return (
    <div className={`flex-container ${isElectron ? 'draggable' : 'home-cover-web' }`}>
      {isElectron ? null :
      <div className="web-menu">
        <div className="web-menu-container">
          <div className="short-title">
            <span className="title">WISSO互播课堂</span>
            <span className="subtitle">© 2020-至今 WISSO 深圳市威騪智能科技有限公司</span>
          </div>
          <div className="short-title">
          <Icon className="icon-setting" onClick={handleSetting}/>
          {/*<FormControl  className={classes.formControl}>*/}
            {/*<FormSelect*/}
                {/*Label={"语言/Language"}*/}
                {/*value={session.lan}*/}
                {/*onChange={(evt: any) => {*/}
                  {/*setSessionInfo({*/}
                    {/*...session,*/}
                    {/*lan: evt.target.value,*/}
                    {/*});*/}
                  {/*changeLanguage(lanTypes[evt.target.value].path);*/}
                {/*}}*/}
                {/*items={lanTypes}*/}
            {/*/>*/}
          {/*</FormControl>*/}
          </div>
        </div>
      </div>
      }
      <div className="custom-card">
        <div className="flex-item cover">
          {isElectron ?
          <>
          <div className="short-title">
            <span className="title">WISSO互播课堂</span>
            <span className="subtitle">© 2020-至今 WISSO 深圳市威騪智能科技有限公司</span>
          </div>
          <div className="cover-placeholder"></div>
          </>
          : <div className="cover-placeholder-web"></div>
          }
        </div>
        <div className="flex-item card">
          <div className="position-top card-menu">
            <HomeBtn handleSetting={handleSetting}/>
          </div>
          <div className="position-content flex-direction-column">
            <FormControl className={classes.formControl}>
              <FormInput Label={t('roomname')} value={session.roomName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    roomName: val
                  });
                }}
                requiredText={required.roomName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormInput Label={t('username')} value={session.yourName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    yourName: val
                  });
                }}
                requiredText={required.yourName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormSelect
                Label={t('roomtype')}
                value={session.roomType}
                onChange={(evt: any) => {
                  setSessionInfo({
                    ...session,
                    roomType: evt.target.value
                  });
                }}
                items={roomTypes}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <RoleRadio value={session.role} onChange={(evt: any) => {
                 setSessionInfo({
                   ...session,
                   role: evt.target.value
                 });
              }} requiredText={required.role}></RoleRadio>
            </FormControl>
            <Button name={t('join')} onClick={handleSubmit}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(HomePage);
