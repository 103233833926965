import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
// @ts-ignore
const resources = {
    en: {
        translation: {
            finish: "Finish",
            confirm: "Confirm",
            cancel: "Cancel",
            join: "Join",
            send:"Send",
            kick:"kick",
            roomname: "Room Name",
            username: "Your Name",
            host: "Host",
            member: "Member",
            camera: "Camera",
            microphone: "Microphone",
            speaker: "Speaker",
            counting: "Start timing",
            countingend: "End timing",
            delay: "Delay",
            network: "Network",
            uploadimg: "Upload image",
            uploadFile: "Upload file",
            uploadaudiovideo : "Upload audio/video",
            stopsharebtn : "stopsharebtn",
            documentlist : "Document Center",
            boardname : "whiteboard",
            msglist : "Chatroom",
            studentlist : "Members",
            startrecord : "Start Recording",
            stoprecord : "Stop Recording",
            leaveroomtips : "Are you sure to exit the classroom？",
            stopsharetips : "Canceled screen sharing",
            sharefailtips : "Screen Sharing Failed",
            loginfailedtips : "Login failed",
            loginfailednettips : "login failure, please checkout network",
            recordtips : "Start recording",
            recordfailtips1 : "acquire recording failed",
            recordfailtips2 : "query recording failed",
            recordfailtips3 : "start recording failed",
            recordfailtips4 : "stop recording failed",
            stoprecordtips : "Stop recording",
            recordsuccesstips : "Start recording success",
            record15tips: "Recording too short, at least 15 seconds.",
            whiteboardlock: "whiteboard lock",
            whiteboardunlock: "whiteboard unlock",
            missingrole : "missing role !",
            missingusername : "missing your name !",
            missingroomname : "missing room name !",
            roomtype:"Room type",
            inputmessagetips:"Input  message",
            nonemessagetips:"Speaking not allowed",
            cancelwhiteboard:"Teacher already cancel your whiteboard",
            permitwhitheboard:"Teacher already permit your whiteboard",
            acceptcovideo:"teacher already accept co-video",
            rejectcovideo:"teacher already rejected co-video",
            cancelcovideo:"teacher already canceled co-video",
            replayFail:"Replay Failed please refresh browser",
            login: "Login"

        }
    },
    ch: {
        translation: {
            finish: "完成",
            confirm: "确认",
            cancel: "取消",
            join: "加入课堂",
            send:"发送",
            kick:"踢出",
            roomname: "课堂名称",
            username: "用户名称",
            host: "老师",
            member: "学生",
            camera: "摄像头",
            microphone: "麦克风",
            speaker: "扬声器",
            counting: "计时开始",
            countingend: "计时结束",
            delay: "延迟",
            network: "网络状态",
            uploadimg: "上传图片",
            uploadFile: "上传文档",
            uploadaudiovideo : "上传音视频",
            stopsharebtn : "停止共享",
            documentlist : "文件管理",
            boardname : "白板",
            msglist : "消息列表",
            studentlist : "学生列表",
            startrecord : "开始录制",
            stoprecord : "结束录制",
            leaveroomtips : "离开当前课堂？",
            stopsharetips : "已取消屏幕共享",
            sharefailtips : "屏幕共享失败",
            loginfailedtips : "登录失败",
            loginfailednettips : "登录失败,请检查当前网络",
            recordtips : "开始录制",
            recordfailtips1 : "获取录制信息失败",
            recordfailtips2 : "查询录制信息失败",
            recordfailtips3 : "开始录制操作失败",
            recordfailtips4 : "停止录制信息失败",
            stoprecordtips : "结束录制",
            recordsuccesstips : "录制开启成功",
            record15tips: "录制时长不足15秒",
            whiteboardlock: "白板锁定",
            whiteboardunlock: "白板解除锁定",
            missingrole : "请选择角色!",
            missingusername : "请填写用户名!",
            missingroomname : "请填写课堂名称!",
            roomtype: "课堂类型",
            inputmessagetips:"请输入聊天信息",
            nonemessagetips:"禁言中",
            cancelwhiteboard:"老师已经取消了你的白板操作",
            permitwhitheboard:"老师已经允许了你的白板操作",
            acceptcovideo:"老师接受视频互动申请",
            rejectcovideo:"老师拒绝了视频互动申请",
            cancelcovideo:"老师取消了视频互动",
            replayFail:"回放失败请刷新浏览器",
            login: "登录"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ch",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
