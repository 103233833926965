import React, {useState} from 'react';
import ChatPanel from './chat/panel';
import StudentListBigClass from './student-list-bigclass';
import {useTranslation} from "react-i18next";
import useChatTextbigclass from "../hooks/use-chat-text-bigclass";

export default function Roomboardbigclass (props: any) {
  const {
    role, list, value,
    messages, sendMessage, handleChange
  } = useChatTextbigclass();

  const [active, setActive] = useState('chatroom');
  const [visible, setVisible] = useState(true);
  const { t, i18n } = useTranslation();
  const toggleCollapse = (evt: any) => {
    setVisible(!visible);
  }

  return (
    <>
    {/*<div className={`${visible ? "icon-collapse-off" : "icon-collapse-on" } fixed`} onClick={toggleCollapse}></div>*/}
    {visible ?
    <div className={`small-class chat-board`}>
      <div className="menu">
        <div onClick={() => { setActive('chatroom') }} className={`item ${active === 'chatroom' ? 'active' : ''}`}>{t('msglist')}</div>
        <div onClick={() => { setActive('studentList') }} className={`item ${active === 'studentList' ? 'active' : ''}`}>{t('studentlist')}</div>
      </div>
      {
        active === 'chatroom' ?
        <ChatPanel
          messages={messages}
          value={value}
          sendMessage={sendMessage}
          handleChange={handleChange} />
        :
        <StudentListBigClass
          role={role}
          list={list}
        />
      }
    </div>
    : null}
    </>
  )
}
